/** @param {string} anchor */
export function scrollTo(anchor) {
    const el = document.getElementById(anchor);
    if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
    }
}

/** @param {string} url */
export function browseTo(url) {
    window.location.href = url;
}
